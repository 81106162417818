//Hemlet
import { Helmet } from 'react-helmet-async';

import HelpArticleContainer from "./components/HelpArticleContainer";

//emotion
import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'

//contexts 
import { useScreenContext } from 'context/AppContext/ScreenContext';

//BreadCrums
import BreadcrumbNavigation from "apps/User/AccountSettings/Components/BreadcrumbNavigation";



import { useParams, Link } from "react-router-dom";
import ArticleManager from './articles/HelpArticleData';
const HelpList = () => {

    const {isMobile, isMedium, isLarge} = useScreenContext()


    const { category } = useParams();
    const articles = ArticleManager.HelpArticleData()[category] || [];

    //BreadCrombs
    const BREADCRUMBS = [
        { name: "home", link: `/help` },
        { name: `${category}`, link: `/help/${category}` },
      ]

    return (
        <HelpArticleContainer>
            <Container $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
        <div>
            {(isLarge || isMedium) && (
                <BreadcrumbNavigation breadcrumbs={BREADCRUMBS}/>
            )}
            <Title>{category} Help Articles</Title>
            <List>
                {articles.map(article => (
                    <ListItem key={article.id}>
                        <Link to={`/help/${category}/${article.id}`}>{article.title}</Link>
                    </ListItem>
                ))}
            </List>
        </div>
            </Container>
        </HelpArticleContainer>
    );
};

export default HelpList;


const Container = styled.div`
color: black;
height:100%;
width: 100%;
align-items: start;
justify-content: center;
padding:${props => {
    if (props.$isMobile) return '10px 25px';
    if (props.$isMedium) return '25px 50px;';
    if (props.$isLarge) return '50px 150px;';
    return '0px 150px;'; // Default to large if none match
  }};
margin:auto;
display:flex;
flex-direction:column;
`

const Title = styled.h2`
color:black;
font-weight: 450;
  text-transform: capitalize; 

`

const BodyText = styled.p`
color: black;
`

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  padding: 10px 0px 0px 0px;

    a{
        
        border-bottom: 1px solid black;
        color: black;
        cursor: pointer;
        text-decoration: none;
        display:inline-block;
        &:hover{
            filter: brightness(1.1);
            color:orange;
            border-bottom: 1px solid orange;
        }
    }
`;

