// /(Main page with tabs)


//Hemlet
import { Helmet } from 'react-helmet-async';

//emotion
import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {keyframes} from '@emotion/react'

//context
import {useState, useEffect, useRef } from "react";
import { useNavigate, Link } from 'react-router-dom';

//AppNav w cart shit
import { AppNavBarManagerProvider } from "context/AppNavBarContext";
import NavBarLogout from 'pages/home/header/NavBarLogout';

import AccountMenu from 'apps/User/AccountSettings/Components/AccountMenu';
import { useScreenContext } from 'context/AppContext/ScreenContext';

import BreadcrumbNavigation from 'apps/User/AccountSettings/Components/BreadcrumbNavigation';
import { NAVBAR_HEIGHT } from 'components/constants';

import ArticleManager from './articles/HelpArticleData';

// const BREADCRUMBS = [
//     { name: "account settings", link: "/account-settings" },
//     { name: "profile", link: "/account-settings/profile" },
//     { name: "edit profile", link: "/account-settings/profile/edit" },
//   ]




  const Help = ({...props}) => {

    const {isMobile, isMedium, isLarge} = useScreenContext()

    const BREADCRUMBS = [
        { name: "help", link: "/help" },
      ]

     const [activeTab, setActiveTab] = useState('user')

     const selectedTab = (tab) => {
        console.log("tab selected:", tab)
        setActiveTab(tab)
     }
    return(
        <>
            <Helmet>
                <title>Customer Service | GoghNow Help Center</title>
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Customer Service | GoghNow Help Center" />
                <meta name="description" content="Customer Service - GoghNow Help Center" />
                <meta property="og:image" content="images/cta-logo-one.png" />
            </Helmet>
            <AppNavBarManagerProvider>
            <SpecialPageContainer>
                <SpecialNavBarLogin/>
                <SpecialPageContentContainer>
                    <Container $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                        {/* {(isLarge || isMedium) && (
                            <BreadcrumbNavigation breadcrumbs={BREADCRUMBS}/>
                        )} */}
                        <Heading>Help Center</Heading>
                        <FlexContainerWithOptions>

                        <TabContainer>
                            <TabContainerButton onClick={() => selectedTab('user')}  $isActive={'user'===activeTab} >User</TabContainerButton>
                            <TabContainerButton onClick={() => selectedTab('provider')} $isActive={'provider'===activeTab}>Provider</TabContainerButton>
                            <TabContainerButton onClick={() => selectedTab('business')} $isActive={'business'===activeTab}>Business</TabContainerButton>
                        </TabContainer>
                        
                            {/* Recommended Blog Posts */}
                            <HeadingTargeted>Recommended Guides</HeadingTargeted>
                            {/* <List> */}
                            <ArticleFlexContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                {ArticleManager.HelpArticleData()[activeTab].slice(0, 2).map(article => (
                                    
                                        <ArticleContainer to={`/help/${activeTab}/${article.id}`} $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                                            <ArticleTitle>{article.title}</ArticleTitle>
                                            <ArticleDescription>{article.description}</ArticleDescription>


                                        </ArticleContainer>
                                ))}
                            </ArticleFlexContainer>
                            {/* </List> */}

                            {/* View All Blogs */}
                            <List>
                                <ListItem>
                                <Link to={`/help/${activeTab}`}>View All {activeTab} Articles</Link>
                                </ListItem>
                            </List>
                        </FlexContainerWithOptions>



                    </Container>
                </SpecialPageContentContainer>
            </SpecialPageContainer>
            </AppNavBarManagerProvider>
        </>
    )
  }


  export default Help



  const SpecialPageContainer = styled.div`
display: flex;
flex-direction: column;
height:100dvh;
width: 100dvw;
overflow:hidden;
background-color: white;
`

const SpecialNavBarLogin = styled(NavBarLogout)`
    // position:fixed;
    background-color: #DDDDDD;
    // top:0px;
`

const SpecialPageContentContainer = styled.div`
display: flex;
flex-direction: column;
height: 100%;
flex-grow: 1;
flex-shrink: 1;
overflow-y: scroll;
overflow-x: hidden;
align-items: center;
`

const Container = styled.div`
display: flex;
flex-direction: column;
justify-content:start;
align-items: center;
max-width: 1080px;
width:100%;
padding: ${props => {
    if (props.$isMobile) return '15px 15px';
    if (props.$isMedium) return '25px 25px';
    if (props.$isLarge) return '50px 50px';
    return '25px 25px'; // Default to large if none match
  }};
  margin-left: auto;
  margin-right: auto;
`

const FlexContainerWithOptions = styled.div`
display: flex;
flex-direction: column;
color: black;
width: 100%;
justify-content: start;
align-items: start;
`

const Heading = styled.h1`
color: black;
`


const TabContainer = styled.div`
display:flex;
width:100%;
border-bottom: 1px solid #CCCCCC;
// min-height: ${NAVBAR_HEIGHT};
`
const TabContainerButton = styled.button`
  color: ${(props) => (props.$isActive ? 'black' : '#00000080')};
  font-weight: bold;
  border: none; 
  border-bottom: ${(props) => (props.$isActive ? '2px solid black' : 'none')};
  padding: 20px 25px 20px 25px;
//   margin: 10px 0px;
  cursor: pointer;
  background-color: ${(props) => (props.$isActive ? 'buttonface' : 'transparent')};


`






const HeadingTargeted = styled.h2`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
  padding-top: 25px;
  a{
    color: blue;
  }
`;








const ArticleFlexContainer = styled.div`
display: flex;
width: 100%;
flex-wrap: wrap;
gap: 10px;
padding: 15px 0px;
`
const ArticleContainer = styled(Link)`
display: flex;
flex-direction:column;
width: ${props => {
    if (props.$isMobile) return 'calc(100% - 10px)';
    if (props.$isMedium) return 'calc(50% - 10px)';
    if (props.$isLarge) return 'calc(33.3333% - 10px)';
    return 'calc(33.3333% - 10px)'; // Default to large if none match
  }};

`
const ArticleTitle = styled.p`
font-size: 1.125em;
font-weight: 500;
color:black;
text-decoration: underline;
margin:0px;
`

const ArticleDescription = styled.p`
font-size: 1.0em;
font-weight: 300;
color: #6a6a6a;
display: -webkit-box;
-webkit-line-clamp: 2; 
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
white-space: pre-wrap;
margin:0px;

`




const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ListItem = styled.li`
  margin-bottom: 8px;
  
  a {
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
    text-transform: capitalize; 


    &:hover {
      text-decoration: underline;
      color: #0056b3;
    }
  }
`;