//Hemlet
import { Helmet } from 'react-helmet-async';

//CONSTANTS
import { NAVBAR_HEIGHT } from "components/constants";

//emotions
import styled from '@emotion/styled'
import { css, cx, keyframes } from '@emotion/css';

//SVG
import { ReactComponent as GoghNowLogo } from 'assets/cta-logo-one.svg';
import { ReactComponent as GoghNowLogoSM } from 'assets/cta-logo-oneSM.svg';

//Navbar
import NavBarAccount from '../NavBarComponents/NavBarAccount';

//AppNav w cart shit
import { AppNavBarManagerProvider } from "context/AppNavBarContext";

//Sceen
import { useScreenContext } from 'context/AppContext/ScreenContext';

//React
import {useState } from "react";
import { useNavigate, Link } from 'react-router-dom';

//Display Bookings
import DisplayBookings from './Components/DisplayBookingsComponents';
import { sampleUserRequestData } from 'model/RequestObjects/objectUserRequest';
// import AccountMenu from '../AccountSettings/Components/AccountMenu';
//Icons
import profileIcon from 'assets/profile-icon.svg';
import accountIcon from 'assets/account-icon.svg';

// import TogglePanel from 'utility/togglePanel';

const BookingsPage = ({...props}) => {


    //Navigation
    const navigate = useNavigate();


    const {isMobile, isMedium, isLarge} = useScreenContext()

    // let toggleOptions = ['All','Upcoming','Past']
    const menuData = [
        {
          title: "Account Management",
          items: [
            { 
                title: "Profile",
                description:'The information you share will be used across GoghNow to help providers get to know you',
                img: profileIcon,
                onClick: () => {
                    console.log("Go to profile") 
                    navigate('/account-settings/profile')
                },

            },
            { 
                title: "Account",
                description:'Provide personal details and how we can reach you',
                img: accountIcon,
                onClick: () => {
                    console.log("Go to Bookings") 
                    navigate('/account-settings/account')
                }
            },
          ],
        },
        // {
        //   title: Bookings, // No title, just a single item
        //   items: [
        //     { label: "History", onClick: () => console.log("Sign Out"), isDestructive: true },
        //   ],
        // },
      ];

    //   const handleToggle = (selectedTab) => {
    //     console.log("Selected Tab:", selectedTab); // For debugging

    //   };

    return (
        <>
            <AppNavBarManagerProvider>
            <SpecialPageContainer>
                <SpecialNavBarLogin/>
                <SpecialPageContentContainer>
                    <Container $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                        <Heading>Bookings</Heading>
                        {/* <TogglePanel
                            options={toggleOptions} 
                            onToggle={(tab) => handleToggle(tab)}  /> */}
                        <FlexContainerWithOptions>
                            <DisplayBookings data={sampleUserRequestData} col={3}></DisplayBookings>
                        </FlexContainerWithOptions>
                    </Container>
                </SpecialPageContentContainer>
            </SpecialPageContainer>
            </AppNavBarManagerProvider>

        </>
    )
}

export default BookingsPage

const SpecialPageContainer = styled.div`
display: flex;
flex-direction: column;
height:100dvh;
width: 100dvw;
overflow:hidden;
background-color: white;
`

const SpecialNavBarLogin = styled(NavBarAccount)`
    // position:fixed;
    background-color: transparent !important;
    // top:0px;
`

const SpecialPageContentContainer = styled.div`
display: flex;
flex-direction: column;
height: 100%;
flex-grow: 1;
flex-shrink: 1;
overflow-y: scroll;
overflow-x: hidden;
align-items: center;
`

const Container = styled.div`
display: flex;
flex-direction: column;
justify-content:center;
// max-width: 1080px;
width:1080px;
padding: ${props => {
    if (props.$isMobile) return '5px 5px';
    if (props.$isMedium) return '15px 15px';
    if (props.$isLarge) return '25px 25px';
    return '25px 25px'; // Default to large if none match
  }};
  margin-left: auto;
  margin-right: auto;
  gap: 15px;
`

const FlexContainerWithOptions = styled.div`
display: flex;
flex-wrap: wrap;
`

const Heading = styled.h1`
color: black;
`