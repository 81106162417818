//Hemlet
import { Helmet } from 'react-helmet-async';

//CONSTANTS
import { NAVBAR_HEIGHT } from "components/constants";

//emotions
import styled from '@emotion/styled'
import { css, cx, keyframes } from '@emotion/css';

//SVG
import { ReactComponent as GoghNowLogo } from 'assets/cta-logo-one.svg';
import { ReactComponent as GoghNowLogoSM } from 'assets/cta-logo-oneSM.svg';

//Navbar
import NavBarAccount from '../NavBarComponents/NavBarAccount';

//AppNav w cart shit
import { AppNavBarManagerProvider } from "context/AppNavBarContext";

//Sceen
import { useScreenContext } from 'context/AppContext/ScreenContext';

//React
import {useState } from "react";
import { useNavigate, Link } from 'react-router-dom';

//ACCOUNT MENU
import AccountMenu from './Components/AccountMenu';

//Icons
import profileIcon from 'assets/profile-icon.svg';
import accountIcon from 'assets/account-icon.svg';

const AccountSettingsPage = ({...props}) => {


    //Navigation
    const navigate = useNavigate();


    const {isMobile, isMedium, isLarge} = useScreenContext()


    const menuData = [
        {
          title: "Account Management",
          items: [
            { 
                title: "Profile",
                description:'The information you share will be used across GoghNow to help providers get to know you',
                img: profileIcon,
                onClick: () => {
                    console.log("Go to profile") 
                    navigate('/account-settings/profile')
                },

            },
            { 
                title: "Account",
                description:'Provide personal details and how we can reach you',
                img: accountIcon,
                onClick: () => {
                    console.log("Go to Bookings") 
                    navigate('/account-settings/account')
                }
            },
          ],
        },
        // {
        //   title: Bookings, // No title, just a single item
        //   items: [
        //     { label: "History", onClick: () => console.log("Sign Out"), isDestructive: true },
        //   ],
        // },
      ];



    return (
        <>
            <Helmet>
                <title>GoghNow the App: Explore Event Services Near You | GoghNow</title>
                <meta property="og:type" content="website" />
                <meta property="og:title" content="GoghNow | Account Settings" />
                <meta name="description" content="GoghNow view, update, and modify your account" />
                <meta property="og:image" content="images/cta-logo-one.png" />
            </Helmet>
            <AppNavBarManagerProvider>
            <SpecialPageContainer>
                <SpecialNavBarLogin/>
                <SpecialPageContentContainer>
                    <Container $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                        <Heading>Account Settings</Heading>
                        <FlexContainerWithOptions>
                            <AccountMenu data={menuData} col={3}></AccountMenu>
                        </FlexContainerWithOptions>
                    </Container>
                </SpecialPageContentContainer>
            </SpecialPageContainer>
            </AppNavBarManagerProvider>

        </>
    )
}

export default AccountSettingsPage

const SpecialPageContainer = styled.div`
display: flex;
flex-direction: column;
height:100dvh;
width: 100dvw;
overflow:hidden;
background-color: white;
`

const SpecialNavBarLogin = styled(NavBarAccount)`
    // position:fixed;
    background-color: transparent !important;
    // top:0px;
`

const SpecialPageContentContainer = styled.div`
display: flex;
flex-direction: column;
height: 100%;
flex-grow: 1;
flex-shrink: 1;
overflow-y: scroll;
overflow-x: hidden;
align-items: center;
`

const Container = styled.div`
display: flex;
flex-direction: column;
justify-content:center;
max-width: 1080px;
width:auto;
padding: ${props => {
    if (props.$isMobile) return '5px 5px';
    if (props.$isMedium) return '15px 15px';
    if (props.$isLarge) return '25px 25px';
    return '25px 25px'; // Default to large if none match
  }};
  margin-left: auto;
  margin-right: auto;
`

const FlexContainerWithOptions = styled.div`
display: flex;
flex-wrap: wrap;
`

const Heading = styled.h1`
color: black;
`